<template>
  <CRow>
    <CCol col="9">
      <CCard>
        <CCardHeader style="font-weight:bold;">
          {{ userVal.name }}
        </CCardHeader>
        <CCardBody>
          <CInput
            label="Name"
            placeholder="Enter users name"
            v-model="userVal.name"
          />
           <CInput
            label="Email"
            v-model="userVal.email"
            :readonly="true"
          />
          <CInput
            label="Phone"
            v-model="userVal.phone_number"
            :readonly="true"
          />
          <CRow>
            <CCol class="text-right"
              ><CButton color="primary" @click="onUpdateProfileInfo"
                >Update My Info</CButton
              ></CCol
            >
          </CRow>
          <CInput
            label="Old Password"
            placeholder="Enter Old password"
            type="password"
            v-model="userVal.oldPassword"
          />
          <CInput
            label="Password"
            placeholder="Enter password"
            type="password"
            v-model="userVal.password"
          />
          <CInput
            label="Verify Password"
            placeholder="Enter password"
            type="password"
            v-model="userVal.verifyPassword"
          />
          <CRow>
            <CCol class="text-right"
              ><CButton color="primary" @click="onChangePassword"
                >Change Password</CButton
              ></CCol
            >
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="3">
      <CImg :src="userVal.photo" fluid thumbnail v-if="!isLogoEdited" />
      <CImg :src="imageData" fluid thumbnail v-if="isLogoEdited" />
      <label>Upload A Photo</label>
      <CInputFile horizontal custom @change="onFileSelected" />
      <!-- <TheHeaderDropdownAccnt :username.sync="userVal.name"/> -->
    </CCol>
  </CRow>
</template>

<script>
//import usersData from "./UsersData";
import AuthenticationService from "../services/authenticationservice.js";
import DynamoDBService from "../services/dynamodbservice.js";
import UserRoles from "../data/Roles.js";
import constants from "../data/Constants.js";
import store from "../store";
import TheHeaderDropdownAccnt from "../containers/TheHeaderDropdownAccnt";
export default {
  name: "User",
  components: {
    TheHeaderDropdownAccnt,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      changeInfo: null,
      isLogoEdited: false,
      imageData: "",
      selectedSites: [],
      showSite: false,
      siteData: [],
      siteFullDetails: [],
      isEditMode: false,
      credentials: null,
      usersOpened: null,
      roles: UserRoles,
      userVal: {
        name: "",
        email: "",
        phone_number:"",
        oldPassword: "",
        password: "",
        verifyPassword: "",
        photo: "img/upload_photo.png",
      },
    };
  },
  mounted: function() {
    this.getUserData();
  },
  computed: {
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },
  },
  created() {},
  methods: {
    onFileSelected(event) {
      this.isLogoEdited = true;
      this.fileSelected = event[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.fileSelected);
    },
    getUserData() {
      var that = this;
      var params = {
        TableName: constants.USERS_TABLE_NAME,
        Key: { userid: store.state.currentUser.userid },
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getItem(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.userVal.name = data.Item.name;
            that.userVal.email = data.Item.userid;
            that.userVal.phone_number = data.Item.phone_number;
            if (
              data.Item.photo != undefined &&
              data.Item.photo != null &&
              data.Item.photo != ""
            ) {
              that.userVal.photo = data.Item.photo;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onUpdateProfileInfo() {
      if (
        this.userVal.name == undefined ||
        this.userVal.name == null ||
        this.userVal.name == ""
      ) {
        this.$alert(constants.NAME_VALIDATION, constants.INFORMATION);
        return;
      }
      // if (
      //   this.userVal.email == undefined ||
      //   this.userVal.email == null ||
      //   this.userVal.email == ""
      // ) {
      //   this.$alert(constants.EMAIL_VALIDATION, constants.INFORMATION);
      //   return;
      // }
      // if (!this.ValidateEmail(this.userVal.email)) {
      //   this.$alert(constants.EMAIL_VALID_VALIDATION, constants.INFORMATION);
      //   return;
      // }
      if (
        this.fileSelected != undefined &&
        this.fileSelected != null &&
        this.fileSelected != ""
      ) {
        this.uploadToS3(this.fileSelected);
      } else {
        this.updateProfileDB(false);
      }
    },
    async uploadToS3(file) {
      var splitUserID = store.state.currentUser.userid.split("@");
      var params = {
        Bucket: constants.S3_BUCKET_NAME,
        Key: splitUserID[0] + ".png",
        Body: file,
      };

      const dynamoDBService = new DynamoDBService();
      dynamoDBService.uploadToS3(params).then(
        (data) => {
          console.log(data);
          this.userVal.photo = data;
          this.updateProfileDB(true);
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    onChangePassword() {
      if (
        this.userVal.oldPassword == undefined ||
        this.userVal.oldPassword == null ||
        this.userVal.oldPassword == ""
      ) {
        this.$alert(constants.PASSWORD_OLD_VALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.userVal.password == undefined ||
        this.userVal.password == null ||
        this.userVal.password == ""
      ) {
        this.$alert(constants.PASSWORD_VALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.userVal.verifyPassword == undefined ||
        this.userVal.verifyPassword == null ||
        this.userVal.verifyPassword == ""
      ) {
        this.$alert(
          constants.PASSWORD_VERIFY_VALIDATION,
          constants.INFORMATION
        );
        return;
      }
      if (this.userVal.password != this.userVal.verifyPassword) {
        this.$alert(constants.PASSWORD_VERIFY, constants.INFORMATION);
        return;
      }
      this.loader = this.$loading.show();
      const authenticationService = new AuthenticationService();
      authenticationService
        .changePassword(this.userVal.oldPassword, this.userVal.verifyPassword)
        .then(
          (data) => {
            this.loader.hide();
            if(data == "SUCCESS"){
              this.userVal.oldPassword = "";
              this.userVal.password = "";
              this.userVal.verifyPassword = "";
              this.$alert(constants.PROFILE_PASSWORD_CHANGE_SUCCESS, constants.INFORMATION);
            }
          },
          (error) => {
            console.log(error); // user not confirmed with email will trigger here.
            this.$alert(error.message);
          }
        );
    },
    ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    },

    updateProfileDB(isPhotoEdited) {
      if (isPhotoEdited) {
        var params = {
          TableName: constants.USERS_TABLE_NAME,
          Key: { userid: store.state.currentUser.userid },

          UpdateExpression: "set #v1 = :nameVal, #v2 = :photoVal",
          ExpressionAttributeNames: {
            "#v1": "name",
            "#v2": "photo",
          },
          ExpressionAttributeValues: {
            ":nameVal": this.userVal.name,
            ":photoVal": this.userVal.photo,
          },

          ReturnValues: "ALL_NEW",
        };
      } else {
        var params = {
          TableName: constants.USERS_TABLE_NAME,
          Key: { userid: store.state.currentUser.userid },

          UpdateExpression: "set #v1 = :nameVal",
          ExpressionAttributeNames: {
            "#v1": "name",
          },
          ExpressionAttributeValues: {
            ":nameVal": this.userVal.name,
          },

          ReturnValues: "ALL_NEW",
        };
      }

      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.updateItem(params).then(
        (data) => {
          console.log(data);
          console.log("success updateItem profile");
          this.$alert(constants.PROFILE_UPDATE_SUCCESS, constants.INFORMATION);
          store.state.currentUser.name = this.userVal.name;
          store.state.currentUser.photo = this.userVal.photo + '?update=' + Math.floor(Math.random() * 3000);
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
  },
};
</script>
